import api from '@/lib/axios';
import { useUserStore, type IUserState } from '@/store/useUserStore';
import { useAuth, useUser } from '@clerk/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

function UserProvider({ children }: { children: React.ReactNode }) {
  const { isSignedIn, isLoaded } = useUser();
  const { getToken } = useAuth();
  const { setUser, clearUser } = useUserStore();

  // Only fetch user data when the user is signed in and clerk has loaded
  const { data } = useQuery({
    queryKey: ['userData'],
    queryFn: async () => {
      // Double-check that the user is still signed in before making the request
      if (!isSignedIn) {
        throw new Error('User is not signed in');
      }

      try {
        const token = await getToken();

        // If no token, don't make the request
        if (!token) {
          throw new Error('No auth token available');
        }

        const response = await api.post<{
          success: boolean;
          data: IUserState;
        }>(
          `${process.env.NEXT_PUBLIC_API_URL ?? 'https://localhost:4000'}/users/user`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        return response.data.data;
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
      }
    },
    enabled: Boolean(isSignedIn) && isLoaded, // Explicitly convert to boolean to ensure proper evaluation
    retry: false, // Don't retry if the request fails when the user is not signed in
    staleTime: 5 * 60 * 1000, // Cache the data for 5 minutes to avoid unnecessary requests
  });

  // Set user data when available
  useEffect(() => {
    if (data && isSignedIn) {
      setUser(data);
    }
  }, [data, setUser, isSignedIn]);

  // Clear user data when signed out
  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      clearUser();
    }
  }, [isLoaded, isSignedIn, clearUser]);

  return children;
}

export default UserProvider;

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const FIGMA_UPLOADED_STATUS = '@customization/FIGMA_UPLOADED';

export const LOGIN_REQUEST = '@account/login-request';
export const DISMISS_LOGIN = '@account/dismiss-login';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_CURRENT_CREDIT = '@account/UPDATE_CURRENT_CREDIT';
export const SHOW_CREDIT_EXPIRED = '@account/SHOW_CREDIT_EXPIRED';
export const USER_RESTRICTED_FOR_EMAIL_VERIFICATION =
  '@account/USER_RESTRICTED_FOR_EMAIL_VERIFICATION';
export const UPDATE_ACTIVE_USER_ID = '@account/UPDATE_ACTIVE_USER_ID';

export const SET_PRD_HEADING_HIERARCHY = '@prd/SET_PRD_HEADING_HIERARCHY';
export const UPDATE_VERSIONED_PRD = '@prd/UPDATE_VERSIONED_PRD';
export const UPDATE_MAGIC_SELECTOR = '@prd/UPDATE_MAGIC_SELECTOR';
export const UPDATE_SELECTED_PRD = '@prd/UPDATE_SELECTED_PRD';
export const UPDATE_CURRENT_PRD = '@prd/UPDATE_CURRENT_PRD';
export const UPDATE_SECTION_HIGHLIGHTED = '@prd/UPDATE_SECTION_HIGHLIGHTED';
export const UPDATE_HIGHLIGHTED_TEXTS = '@prd/UPDATE_HIGHLIGHTED_TEXTS';
export const UPDATE_BAD_HIGHLIGHTS = '@prd/UPDATE_BAD_HIGHLIGHTS';
export const UPDATE_BASE_PRD = '@prd/UPDATE_BASE_PRD';
export const UPDATE_PRD_PROJECT_DETAILS = '@prd/UPDATE_PRD_PROJECT_DETAILS';
export const TOGGLE_IS_PRD_HISTORY_DRAWER_OPEN =
  '@prd/TOGGLE_IS_PRD_HISTORY_DRAWER_OPEN';
export const RESET_PRD_STORE_TO_INITIAL_STATE =
  '@prd/RESET_PRD_STORE_TO_INITIAL_STATE';
export const UPDATE_TIMER_PRD = '@prd/UPDATE_TIMER_PRD';
export const UPDATE_SHARE_LINK_VISIBILITY = '@prd/UPDATE_SHARE_LINK_VISIBILITY';

export const UPDATE_PROJECT_LIST = '@project/UPDATE_PROJECT_LIST';
export const UPDATE_PROJECT = '@project/UPDATE_PROJECT';

export const UPDATE_HISTORY = '@canvas/UPDATE_HISTORY';
export const UPDATE_CURRENT_STEP = '@canvas/UPDATE_CURRENT_STEP';
export const UPDATE_MOST_CURR_VERSION = '@canvas/UPDATE_MOST_CURR_VERSION';
export const RESET_HISTORY = '@canvas/RESET_HISTORY';
export const UPDATE_SUCCESSFUL_LAYOUTS = '@canvas/UPDATE_SUCCESSFUL_LAYOUTS';
export const ADD_ERROR_LAYOUTS = '@canvas/ADD_ERROR_LAYOUTS';
export const ADD_PROCESSING_LAYOUTS = '@canvas/ADD_PROCESSING_LAYOUTS';
export const REMOVE_PROCESSING_LAYOUT = '@canvas/REMOVE_PROCESSING_LAYOUT';
export const REMOVE_ERROR_LAYOUT = '@canvas/REMOVE_ERROR_LAYOUT';
export const INIT_LAYOUTS = '@canvas/INIT_LAYOUTS';
export const PUSH_UNDO_STACK = 'PUSH_UNDO_STACK';
export const POP_UNDO_STACK = 'POP_UNDO_STACK';
export const PUSH_REDO_STACK = 'PUSH_REDO_STACK';
export const POP_REDO_STACK = 'POP_REDO_STACK';
// ... other action types

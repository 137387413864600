import { create } from 'zustand';
import { persist, type PersistOptions } from 'zustand/middleware';

export interface IUserState {
  email: string;
  firstName: string;
  availableCredits: number;
  projectCount: number;
  isOnboardingDone: boolean;
  componentsCount: number;
  deployedCount: number;
  webAppsCount: number;
  hasUserAcknowledgedErrors: boolean;
}

interface IUserSetters {
  setUser: (user: IUserState) => void;
  clearUser: () => void;
}

type IUserStore = { user: IUserState } & IUserSetters;

type UserPersist = Pick<IUserStore, 'user'>;

const persistOptions: PersistOptions<IUserStore, UserPersist> = {
  name: 'user-storage',
  partialize: state => ({ user: state.user }),
};

export const useUserStore = create<IUserStore>()(
  persist(
    set => ({
      user: {
        email: '',
        projectCount: -1,
        firstName: '',
        availableCredits: -1,
        isOnboardingDone: false,
        componentsCount: -1,
        deployedCount: -1,
        webAppsCount: -1,
        hasUserAcknowledgedErrors: false,
      },
      setUser: user => set({ user }),
      clearUser: () =>
        set({
          user: {
            email: '',
            projectCount: -1,
            firstName: '',
            availableCredits: -1,
            isOnboardingDone: false,
            componentsCount: -1,
            deployedCount: -1,
            webAppsCount: -1,
            hasUserAcknowledgedErrors: false,
          },
        }),
    }),
    persistOptions,
  ),
);

/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  LOGIN_REQUEST,
  DISMISS_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  UPDATE_CURRENT_CREDIT,
  SHOW_CREDIT_EXPIRED,
  USER_RESTRICTED_FOR_EMAIL_VERIFICATION,
  UPDATE_ACTIVE_USER_ID,
} from './actions';

const initialState = {
  user: undefined,
  login: undefined,
  credit: null,
  showCreditExpiredModal: false,
  userRestrictedForVerification: false,
  activeUserId: null,
  isAlert: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        draft.login = true;
      });
    }

    case DISMISS_LOGIN: {
      return produce(state, draft => {
        draft.login = false;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case UPDATE_CURRENT_CREDIT: {
      return produce(state, draft => {
        draft.credit = action.payload?.availableCredit;
      });
    }

    case SHOW_CREDIT_EXPIRED: {
      return produce(state, draft => {
        draft.showCreditExpiredModal = action.payload;
        draft.isAlert = action.isAlert || false;
      });
    }

    case USER_RESTRICTED_FOR_EMAIL_VERIFICATION:
      return produce(state, draft => {
        draft.userRestrictedForVerification = action.payload;
      });

    case UPDATE_ACTIVE_USER_ID:
      return produce(state, draft => {
        draft.activeUserId = action.payload;
      });

    default: {
      return state;
    }
  }
};

export default accountReducer;

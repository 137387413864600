import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import accountReducer from './accountReducer';
import prdReducer from './prdReducer';
import projectReducer from './projectReducer';
import canvasReducer from './canvasReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  account: accountReducer,
  prd: prdReducer,
  project: projectReducer,
  canvas: canvasReducer,
});

export default reducer;

// action - state management
import * as actionTypes from './actions';

export const initialState = {
  projectList: [],
  currentProject: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROJECT_LIST:
      return {
        ...state,
        projectList: action.projectList,
      };

    case actionTypes.UPDATE_PROJECT:
      return {
        ...state,
        currentProject: action.project,
      };

    default:
      return state;
  }
};

export default projectReducer;

'use client';
import { useAuth, useUser } from '@clerk/nextjs';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect, useRef } from 'react';
import { Router } from 'next/router';
import { isDev } from '@/components/Canvas/helpers/getBaseUrl';

// Define the provider component with its props interface
function PHProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Initialize PostHog with environment variables and configuration
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      capture_pageview: false,
      person_profiles: 'identified_only',
      loaded: posthog => {
        if (isDev) {
          // Disable PostHog tracking in development environment
          posthog.opt_out_capturing();
          console.log(
            'PostHog tracking disabled in development mode on localhost',
          );
        }
      },
    });
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

// Export the provider as the default export for the page
export default PHProvider;

import { type AppType } from 'next/app';
import { ClerkProvider, useAuth, useUser } from '@clerk/nextjs';
import { useRouter } from 'next/router';
import {
  AuthLayout,
  CenteredLayout,
  FullPageLayout,
} from '@/components/layouts/FullPageLayout';
import '@/styles/globals.css';
import '@/styles/color-palette.css';
import '@/styles/ForkedDialog.css';
import { Geist, Geist_Mono } from 'next/font/google';
import { SnackbarProvider } from 'notistack';
import 'reactflow/dist/style.css';
import { Toaster } from '@/components/ui/Sonner';
import { Provider } from 'react-redux';
import { store } from '@/store';
import ErrorBoundary from '@/components/ErrorBoundary';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Analytics } from '@vercel/analytics/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect, useRef } from 'react';
import { Router } from 'next/router';
import { isDev } from '@/components/Canvas/helpers/getBaseUrl';
import '@/styles/canvas.css';
import '@/styles/why-choose-grid.css';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import PHProvider from '../providers/posthog';
import PostHogPageView from './PosthogPageView';
import React from 'react';
import Head from 'next/head';
import ChatwootWidget from '@/components/ChatwootWidget';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import UserProvider from '@/providers/user';

const routeConfig = {
  '/sign-in': {
    layout: AuthLayout,
    props: {
      backgroundImage: '/auth-background.jpg',
      showLogo: false,
    },
  },
  '/sign-up': {
    layout: AuthLayout,
    props: {
      backgroundImage: '/auth-background.jpg',
      showLogo: true,
    },
  },
  '/loading-screen': {
    layout: FullPageLayout,
    props: {
      showLogo: false,
      className: 'bg-white',
    },
  },
  '/onboarding': {
    layout: FullPageLayout,
    props: {
      showLogo: false,
    },
  },
  '/404': {
    layout: FullPageLayout,
    props: {
      showLogo: false,
    },
  },
  '/500': {
    layout: CenteredLayout,
    props: {
      showLogo: false,
    },
  },
  // Add specific route for new-dashboard
  // "/new-dashboard": {
  //   layout: DashboardLayout,
  //   props: {},
  // },
} as const;

const geist = Geist({ subsets: ['latin'], variable: '--font-geist' });
const geistMono = Geist_Mono({
  subsets: ['latin'],
  variable: '--font-geist-mono',
});

const queryClient = new QueryClient();

const AppContent = ({ Component, pageProps }: any) => {
  const router = useRouter();

  const getLayout = () => {
    if (Component.displayName === 'ErrorPage' || router.pathname === '/404') {
      const routeSettings = routeConfig['/404'];
      const Layout = routeSettings.layout;
      return (
        <Layout {...routeSettings.props}>
          <Component {...pageProps} />
        </Layout>
      );
    }
    const basePath = router.asPath
      ?.split('?')[0]
      ?.split('#')[0]
      ?.split('/')
      ?.filter(Boolean)[0];
    const path = basePath ? `/${basePath}` : router.asPath;

    const routeSettings = routeConfig[path as keyof typeof routeConfig];

    if (routeSettings) {
      const Layout = routeSettings.layout;
      return (
        <Layout {...routeSettings.props}>
          <Component {...pageProps} />
        </Layout>
      );
    }

    return <Component {...pageProps} />;
  };

  return (
    <div className={`${geist.variable} ${geistMono.variable} font-geist`}>
      {getLayout()}
      <Toaster richColors position="bottom-center" />
    </div>
  );
};

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <title>Creatr</title>
      </Head>

      <ChatwootWidget />
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ClerkProvider {...pageProps}>
            <UserProvider>
              <NuqsAdapter>
                <PHProvider>
                  <SpeedInsights />
                  <Analytics />
                  <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <Provider store={store}>
                      <PostHogPageView />
                      <AppContent Component={Component} pageProps={pageProps} />
                    </Provider>
                  </SnackbarProvider>
                </PHProvider>
              </NuqsAdapter>
            </UserProvider>
          </ClerkProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
};

export default MyApp;
